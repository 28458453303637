@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9bxakr');
  src:  url('../fonts/icomoon.eot?9bxakr#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9bxakr') format('truetype'),
    url('../fonts/icomoon.woff?9bxakr') format('woff'),
    url('../fonts/icomoon.svg?9bxakr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-audio:before {
  content: "\e999";
}
.icon-credit:before {
  content: "\e99a";
}
.icon-media:before {
  content: "\e99b";
}
.icon-story:before {
  content: "\e99c";
}
.icon-style:before {
  content: "\e99d";
}
.icon-text:before {
  content: "\e99e";
}
