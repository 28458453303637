body{ background: #FAFAFA !important;  color: var(--body-font-color); }

::-webkit-input-placeholder {opacity:1; color: #000000;}
:-moz-placeholder{opacity:1; color: #000000;}
::-moz-placeholder{opacity:1; color: #000000;}
:-ms-input-placeholder{opacity:1; color: #000000;} 

/* Color Variable */ 
:root {
    --primary: #f7ab3a;
    --secondary: #ee1085;
    --blue: #89AFE0;
    --light-blue: #B2CAEE;
    --lighter-blue: #D5DEEF;
    --grey: #F1F4FB;
    --white: #FFFFFF;
    --black: #000000;
    --body-font-color: #A4A4A4;
    --off-white: #FAFAFA;
}
  /* Color Variable */